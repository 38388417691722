<template>
  <div>
    
    

    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
     

          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Inicial:"
            >
            <b-form-datepicker
                 id="PeridoIni"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoIni"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Final:"
            >
            <b-form-datepicker
                 id="PeridoFin"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoFin"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>


              <b-col md="6" xl="3" class="mb-1">
                 <b-form-group
                 label="Data Corte:"
                 >
                 <b-form-datepicker
                      id="DataCorte"
                      locale="pr-BR"
                      :date-format-options="{
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                      }"
                      today-button
                      reset-button
                      v-model="DataCorte"
                      placeholder="Selecione"
                    />
                       </b-form-group>
                 </b-col>
     
            
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Motivo Devoluções:">
                <multiselect
                    v-model="Motivo"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="MotivoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Vendedor:" >
                <multiselect
                    v-model="CodVend"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodVendOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  >
                  </multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Coordenador:" >
                <multiselect
                    v-model="CodCoord"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodCoordOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Gerente:" >
                <multiselect
                    v-model="CodGer"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodGerOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>


          
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Região:" >

                  <multiselect
                    v-model="CodReg"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodRegOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          

          </b-row>

          <b-row>


            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Status Vendedor/Gerente/Coordenador:" >
                <multiselect
                    v-model="Ativo"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="AtivoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
            </b-col>

          </b-row>


        <b-row>
            
            <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>
              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              type="submit"
              @click="ConsultarRelatorio"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
          </b-col>
         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>

    <div class="col-lg-12 col-24">
      <div class="row match-height">
        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">Dias Úteis:
                <i class="bi bi-calendar-event" style="font-size: 2rem; color: blue; float:right;" ></i>
              </h4>
              <center><h4 class="fw-bolder mb-1"> {{BoletimDiarioVendaCAB1.UTEIS}}</h4></center>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">Dias Corridos:
                <i class="bi bi-calendar-event" style="font-size: 2rem; color: orange; float:right;"></i>
              </h4>
              <center><h4 class="fw-bolder mb-1">{{BoletimDiarioVendaCAB1.CORRIDOS}}</h4></center>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">% Ideal:
                <i class="bi bi-clipboard2-heart" style="font-size: 2rem; color: red; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB1.IDEAL,'percent')}}</h4>
            </div>
          </div>
        </div>


      <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">% Real:
                <i class="bi bi-clipboard2-check" style="font-size: 2rem; color: green; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB1.R,'percent')}}</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">Há 1 Mês:
                <i class="bi bi-clock-history" style="font-size: 2rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB1.R1,'percent')}}</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">Há 2 Meses:
                <i class="bi bi-clock" style="font-size: 2rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB1.R2,'percent')}}</h4>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="col-lg-12 col-24">
      <div class="row match-height">
        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">BP Valor:
                <i class="bi bi-cash-coin" style="font-size: 2rem; color: green; float:right;" ></i>
              </h4>
              <h4 class="fw-bolder mb-1"> {{ "R$ " +  this.TextFormat(BoletimDiarioVendaCAB2.METVAL,'money')}}</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">BP Volume:
                <i class="bi bi-box-seam" style="font-size: 2rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.METVOL,'money') + " Kg"}} </h4>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">R$ Entrada:
                <i class="bi bi-cash-coin" style="font-size: 2rem; color: green; float:right;" ></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ "R$ " +  this.TextFormat(BoletimDiarioVendaCAB2.ENTRADATOTAL,'money')}}</h4>
            </div>
          </div>
        </div>


      <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">KG Entrada:
                <i class="bi bi-box-seam" style="font-size: 2rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.ENTRADAPESO,'money') + " Kg"}}</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">R$ Líquido:
                <i class="bi bi-cash-coin" style="font-size: 2rem; color: green; float:right;" ></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ "R$ " +  this.TextFormat(BoletimDiarioVendaCAB2.LIQUIDO,'money')}}</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-2 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">KG Líquido:
                <i class="bi bi-box-seam" style="font-size: 2rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.LIQUIDOKG,'money') + " Kg"}}</h4>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="col-lg-12 col-24">
      <div class="row match-height">
        <div class="col-lg-3 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">R$ Devoluções:
                <i class="bi bi-cash-coin" style="font-size: 2rem; color: red; float:right;" ></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ "R$ " +  this.TextFormat(BoletimDiarioVendaCAB2.DEVOL,'money')}}</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">KG Devoluções:
                <i class="bi bi-box-seam" style="font-size: 2rem; color: red; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.DEVOLKG,'money') + " Kg"}}</h4>
            </div>
          </div>
        </div>

     

        <div class="col-lg-3 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">R$ Corte:
                <i class="bi bi-scissors" style="font-size: 2rem; color: red; float:right;" ></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ "R$ " +  this.TextFormat(BoletimDiarioVendaCAB2.VLRCORTE,'money')}}</h4>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">KG Corte:
                <i class="bi bi-scissors" style="font-size: 2rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.QTDCORTE,'money') + " Kg"}}</h4>
            </div>
          </div>
        </div>

      </div>
    </div>


    <div class="col-lg-12 col-24">
      <div class="row match-height">

      <div class="col-lg-6 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">% Valor:
                <i class="bi bi-cash-coin" style="font-size: 4rem; color: green; float:right;" ></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.PERCVAL,'percent') }}</h4>
            </div>
          </div>
        </div>

     <div class="col-lg-6 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">R$ a Faturar:
                <i class="bi bi-cash-coin" style="font-size: 4rem; color: green; float:right;" ></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ "R$ " +  this.TextFormat(BoletimDiarioVendaCAB2.AFATURAR,'money')}}</h4>
            </div>
          </div>
        </div>

      <div class="col-lg-6 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">% Volume:
                <i class="bi bi-box-seam" style="font-size: 4rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.PERCVOL,'percent') }}</h4>
            </div>
          </div>
        </div>
    


   

        <div class="col-lg-6 col-md-3 col-6">
          <div class="card">
            <div class="card-body pb-50" style="position: relative;">
              <h4 class="fw-bolder mb-1">KG a Faturar:
                <i class="bi bi-box-seam" style="font-size: 4rem; color: orange; float:right;"></i>
              </h4>
              <h4 class="fw-bolder mb-1">{{ this.TextFormat(BoletimDiarioVendaCAB2.AFATURARKG,'money') + " Kg"}}</h4>
            </div>
          </div>
        </div>

        
      </div>
    </div>
    
    <div class="col-12" v-if="TabRelBoletimDiarioVendaPosition >= 0">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       type="submit"
                       @click="TabRelBoletimDiarioVendaRowBack(-1)"
                       v-if="TabRelBoletimDiarioVendaPosition > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div  v-if="TabRelBoletimDiarioVendaPosition == 0">
                

                <h4 class="fw-bolder mb-1">Detalhe</h4>  
                
                <div id="TabRelBoletimDiarioVendaNV1">
                
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelBoletimDiarioVendaNV1"
                  :fields="TabRelBoletimDiarioVendaNV1fields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                 <template #cell(METVOL)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(METVAL)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(ENTRADATOTAL)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(ENTRADAPESO)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(DEVOL)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(DEVOLKG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(LIQUIDO)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(LIQUIDOKG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>
                 
                 <template #cell(PERCVAL)="row">
                      {{ TextFormat(row.value,'percent') }}
                 </template>

                 <template #cell(PERCVOL)="row">
                      {{ TextFormat(row.value,'percent') }}
                 </template>

                 <template #cell(AFATURAR)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(AFATURARKG)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(VLRCORTE)="row">
                      {{ 'R$ ' + TextFormat(row.value,'money') }}
                 </template>

                 <template #cell(QTDCORTE)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                 <template v-slot:custom-foot>
                    <tr>
                      <td class="bg-secondary text-white" colspan="10">
                       Total
                      </td>
  
                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(BoletimDiarioVendaCAB2.METVOL,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.METVAL,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(BoletimDiarioVendaCAB2.ENTRADATOTAL,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.ENTRADAPESO,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(BoletimDiarioVendaCAB2.DEVOL,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.DEVOLKG,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(BoletimDiarioVendaCAB2.LIQUIDO,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.LIQUIDOKG,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.PERCVAL,'percent')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.PERCVOL,'percent')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.AFATURAR,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ TextFormat(BoletimDiarioVendaCAB2.AFATURARKG,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{ 'R$ ' + TextFormat(BoletimDiarioVendaCAB2.VLRCORTE,'money')}}
                      </td>

                      <td class="bg-secondary text-white" > 
                        {{  TextFormat(BoletimDiarioVendaCAB2.QTDCORTE,'money')}}
                      </td>

                    
                      
                    </tr>
                 </template>

                </b-table>
                
                </div>
                
                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelBoletimDiarioVendaNV1','RelatorioBolerimDiarioVendas')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
                
                
              </div>

 

             </div>
            </div>
    </div>



  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue'
import { Util } from 'leaflet'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      BoletimDiarioVendaCAB1: {
        UTEIS: 0,
        CORRIDOS: 0,
        IDEAL: 0,
        R: 0,
        R1: 0,
        R2: 0

      },
      BoletimDiarioVendaCAB2: {
        METVOL: 0,
        METVAL: 0,
        ENTRADATOTAL: 0,
        ENTRADAPESO: 0,
        DEVOL: 0,
        DEVOLKG: 0,
        LIQUIDO: 0,
        LIQUIDOKG: 0,
        PERCVAL: 0,
        PERCVOL: 0,
        AFATURAR: 0,
        AFATURARKG: 0,
        VLRCORTE: 0,
        QTDCORTE: 0,

      },

      TabRelBoletimDiarioVendaNV1: [
      ],
      TabRelBoletimDiarioVendaNV1fields: [
        { key: 'CODREG', label: 'Cód.Região', sortable: true, tdClass: 'td200'  },
        { key: 'NOMEREG', label: 'Região', sortable: true, tdClass: 'td200'  },
        { key: 'CODGER', label: 'Cód.Gerente', sortable: false, tdClass: 'td200' },
        { key: 'GERENTE', label: 'Gerente', sortable: true,tdClass: 'td200' },
        { key: 'CODGERREG', label: 'Cód.Gerente Regional', sortable: false, tdClass: 'td200' },
        { key: 'GERENTEREG', label: 'Gerente Regional', sortable: true,tdClass: 'td200' },
        { key: 'CODCOORD', label: 'Cod.Coordenador', sortable: true,tdClass: 'td200' },
        { key: 'COORDENADOR', label: 'Coordenador', sortable: true,tdClass: 'td200' },
        { key: 'CODVEND', label: 'Cód.Vendedor', sortable: false,tdClass: 'td200' },
        { key: 'APELIDO', label: 'Vendedor', sortable: true,tdClass: 'td200' },
        { key: 'METVOL', label: 'BP Volume', sortable: true,tdClass: 'td200' },
        { key: 'METVAL', label: 'BP Valor', sortable: true,tdClass: 'td200' },
        { key: 'ENTRADATOTAL', label: 'R$ Entrada', sortable: true,tdClass: 'td200' },
        { key: 'ENTRADAPESO', label: 'KG Entrada', sortable: true,tdClass: 'td200' },
        { key: 'DEVOL', label: 'R$ Devoluções', sortable: true,tdClass: 'td200' },
        { key: 'DEVOLKG', label: 'KG Devolução', sortable: true,tdClass: 'td200' },
        { key: 'LIQUIDO', label: 'R$ Líquido', sortable: true,tdClass: 'td200' },
        { key: 'LIQUIDOKG', label: 'KG Líquido', sortable: true,tdClass: 'td200' },
        { key: 'PERCVAL', label: '% Valor', sortable: true,tdClass: 'td200' },
        { key: 'PERCVOL', label: '% Volume', sortable: true,tdClass: 'td200' },
        { key: 'AFATURAR', label: 'R$ a Faturar', sortable: true,tdClass: 'td200' },
        { key: 'AFATURARKG', label: 'KG a Faturar', sortable: true,tdClass: 'td200' },
        { key: 'VLRCORTE', label: 'R$ Corte', sortable: true,tdClass: 'td200' },
        { key: 'QTDCORTE', label: 'KG Corte', sortable: true,tdClass: 'td200' },
        
      ],
      PeridoIni: '',
      PeridoFin: '',
      DataCorte: '',
      Motivo: { "name": "Comercial", "code": "Comercial" },
      MotivoOption: [
        { name: 'Comercial', code: 'Comercial' },
        { name: 'Fiscal', code: 'Fiscal' },
        { name: 'Logistica', code: 'Logistica' },
        { name: 'Fabrica', code: 'Fabrica' },
        { name: 'Marketing', code: 'Marketing' },
        { name: 'Sistema', code: 'Sistema' },
        { name: 'Todos', code: 'T' },
      ],     
      CodVend: [],
      CodVendOption: [],     
      CodCoord: [],
      CodCoordOption: [],     
      CodGer: [],
      CodGerOption: [],     
      CodReg: [],
      CodRegOption: [],
      Ativo: { "name": "Todos", "code": "T" },
      AtivoOption: [
        { name: 'Ativo', code: 'S' },
        { name: 'Inativo', code: 'N' },
        { name: 'Todos', code: 'T' },
      ],
      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TabRelBoletimDiarioVendaPosition: -1,
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Vendedor`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.CodVendOption = response.data
            this.CodCoordOption = response.data
            this.CodGerOption = response.data

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Regiao`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CodRegOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio() {
      Spinner.StarLoad()
      
      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio


      //Inicio Dados Base Relatorio
      this.TabRelBoletimDiarioVendaNV1 = []            
      this.BoletimDiarioVendaCAB1.UTEIS= 0
      this.BoletimDiarioVendaCAB1.CORRIDOS= 0
      this.BoletimDiarioVendaCAB1.IDEAL= 0
      this.BoletimDiarioVendaCAB1.R= 0
      this.BoletimDiarioVendaCAB1.R1= 0
      this.BoletimDiarioVendaCAB1.R2= 0
      this.BoletimDiarioVendaCAB2.METVOL= 0
      this.BoletimDiarioVendaCAB2.METVAL= 0
      this.BoletimDiarioVendaCAB2.ENTRADATOTAL= 0
      this.BoletimDiarioVendaCAB2.ENTRADAPESO= 0
      this.BoletimDiarioVendaCAB2.DEVOL= 0
      this.BoletimDiarioVendaCAB2.DEVOLKG= 0
      this.BoletimDiarioVendaCAB2.LIQUIDO= 0
      this.BoletimDiarioVendaCAB2.LIQUIDOKG= 0
      this.BoletimDiarioVendaCAB2.PERCVAL= 0
      this.BoletimDiarioVendaCAB2.PERCVOL= 0
      this.BoletimDiarioVendaCAB2.AFATURAR= 0
      this.BoletimDiarioVendaCAB2.AFATURARKG= 0
      this.BoletimDiarioVendaCAB2.VLRCORTE= 0
      this.BoletimDiarioVendaCAB2.QTDCORTE= 0
      // Fim Limpa Dados Retorno


      //Inicio Volta Todos Niveis para posição inicial
      this.TabRelBoletimDiarioVendaPosition =  0
      this.TabRelBoletimDiarioVendaRowBack(0)
      //Fim Volta Todos Niveis para posição inicial

      
       //Inicio Pega parametros de Parametros
       if(this.PeridoIni != "")
       {
         const Filtro = {
           parametro: 'PERIODO_INI',
           valor: this.PeridoIni,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.PeridoFin != "")
       {
         const Filtro = {
           parametro: 'PERIODO_FIN',
           valor: this.PeridoFin,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.DataCorte != "")
       {
         const Filtro = {
           parametro: 'DATACORTE',
           valor: this.DataCorte,
         }
         this.FiltroRelatorio.push(Filtro)
       }     
      
      if(this.CodVend != null){
        if(Array.isArray(this.CodVend) == false)
        {
          const Filtro = {
            parametro: 'CODVEND',
            valor: this.CodVend.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }
    
      if(this.CodCoord != null){
         if(Array.isArray(this.CodCoord) == false)
         {
           const Filtro = {
             parametro: 'CODCOORD',
             valor: this.CodCoord.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }

      if(this.CodGer != null){
         if(Array.isArray(this.CodGer) == false)
         {
           const Filtro = {
             parametro: 'CODGER',
             valor: this.CodGer.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }

      
      if(this.Motivo != null){
        if(Array.isArray(this.Motivo) == false)
        {
          const Filtro = {
            parametro: 'MOTIVO',
            valor: this.Motivo.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }

      
      this.CodReg.forEach(Item => {
        const Filtro = {
          parametro: 'CODREG',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })
    
      
      if(this.Ativo != null){
         if(Array.isArray(this.Ativo) == false)
         {
           const Filtro = {
             parametro: 'ATIVO',
             valor: this.Ativo.code,
           }
           this.FiltroRelatorio.push(Filtro)
         }
      }

      //Fim Pega parametros de Parametros


      let ParametroJson = ''
      let URLpost = ''
      
      if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelBoletimDiarioVenda?CODUSU=${usuario.codusu}&Nivel=CAB1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.BoletimDiarioVendaCAB1 = response.data[0]
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }

    if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelBoletimDiarioVenda?CODUSU=${usuario.codusu}&Nivel=NIV1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabRelBoletimDiarioVendaNV1 = response.data
            
            this.BoletimDiarioVendaCAB2.METVOL= this.TabRelBoletimDiarioVendaNV1Total("METVOL")
            this.BoletimDiarioVendaCAB2.METVAL= this.TabRelBoletimDiarioVendaNV1Total("METVAL")
            this.BoletimDiarioVendaCAB2.ENTRADATOTAL= this.TabRelBoletimDiarioVendaNV1Total("ENTRADATOTAL")
            this.BoletimDiarioVendaCAB2.ENTRADAPESO= this.TabRelBoletimDiarioVendaNV1Total("ENTRADAPESO")
            this.BoletimDiarioVendaCAB2.DEVOL= this.TabRelBoletimDiarioVendaNV1Total("DEVOL")
            this.BoletimDiarioVendaCAB2.DEVOLKG= this.TabRelBoletimDiarioVendaNV1Total("DEVOLKG")
            this.BoletimDiarioVendaCAB2.LIQUIDO= this.TabRelBoletimDiarioVendaNV1Total("LIQUIDO")
            this.BoletimDiarioVendaCAB2.LIQUIDOKG= this.TabRelBoletimDiarioVendaNV1Total("LIQUIDOKG")
            this.BoletimDiarioVendaCAB2.PERCVAL= this.TabRelBoletimDiarioVendaNV1Total("PERCVAL")
            this.BoletimDiarioVendaCAB2.PERCVOL= this.TabRelBoletimDiarioVendaNV1Total("PERCVOL")
            this.BoletimDiarioVendaCAB2.AFATURAR= this.TabRelBoletimDiarioVendaNV1Total("AFATURAR")
            this.BoletimDiarioVendaCAB2.AFATURARKG= this.TabRelBoletimDiarioVendaNV1Total("AFATURARKG")
            this.BoletimDiarioVendaCAB2.VLRCORTE= this.TabRelBoletimDiarioVendaNV1Total("VLRCORTE")
            this.BoletimDiarioVendaCAB2.QTDCORTE= this.TabRelBoletimDiarioVendaNV1Total("QTDCORTE")

          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }


     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabRelBoletimDiarioVendaNV1Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'METVOL':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.METVOL }, 0.0)
        break

        case 'METVAL':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.METVAL }, 0.0)
        break

        case 'ENTRADATOTAL':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.ENTRADATOTAL }, 0.0)
        break

        case 'ENTRADAPESO':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.ENTRADAPESO }, 0.0)
        break

        case 'ENTRADAPESO':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.ENTRADAPESO }, 0.0)
        break

        case 'DEVOL':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.DEVOL }, 0.0)
        break
        
        case 'DEVOLKG':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.DEVOLKG }, 0.0)
        break

        case 'LIQUIDO':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.LIQUIDO }, 0.0)
        break

        case 'LIQUIDOKG':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.LIQUIDOKG }, 0.0)
        break

        case 'PERCVAL':
          const T_METVAL = this.TabRelBoletimDiarioVendaNV1Total("METVAL")
          const T_LIQUIDO = this.TabRelBoletimDiarioVendaNV1Total("LIQUIDO")

          if(T_METVAL > 0)
          {
             Valor = (T_LIQUIDO/T_METVAL)*100
          }

        break

        case 'PERCVOL':
          const T_METVOL = this.TabRelBoletimDiarioVendaNV1Total("METVOL")
          const T_LIQUIDOKG = this.TabRelBoletimDiarioVendaNV1Total("LIQUIDOKG")

          if(T_METVOL > 0)
          {
             Valor = (T_LIQUIDOKG/T_METVOL)*100
          }

        break

        case 'AFATURAR':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.AFATURAR }, 0.0)
        break

        case 'AFATURARKG':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.AFATURARKG }, 0.0)
        break

        case 'QTDCORTE':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.QTDCORTE }, 0.0)
        break

        case 'VLRCORTE':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.VLRCORTE }, 0.0)
        break

        case 'VENDA':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.VENDA }, 0.0)
        break

        case 'DEVOL_TOTAL':
          Valor = this.TabRelBoletimDiarioVendaNV1.reduce((accum, TabRelBoletimDiarioVendaNV1) => { return accum + TabRelBoletimDiarioVendaNV1.DEVOL_TOTAL }, 0.0)
        break
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabRelBoletimDiarioVendaRowBack(RowBackTo) {
       if(RowBackTo == -1){
         this.TabRelBoletimDiarioVendaPosition = this.TabRelBoletimDiarioVendaPosition-1
       }else{
        this.TabRelBoletimDiarioVendaPosition = RowBackTo
       }
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      let DataExport = ""
      switch (ElementToExport) {
                case 'TabRelBoletimDiarioVendaNV1':
                 DataExport = this.TabRelBoletimDiarioVendaNV1
                break
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
  },
  computed: {
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.PeridoIni = new Date()
      this.PeridoFin = new Date()
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
